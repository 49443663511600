<template>
  <div :style="PreviewMode || Editing ? 'margin-top: 60px;' : 'margin-top: 60px;margin-left:100px;'">
    <v-dialog v-model="SuiteTemplateDialog" max-width="800">
        <v-card flat tile>
            <v-card-title class="white--text recordtoolbar smalloverline">
          New Suite Template
         </v-card-title>
            <v-card-text>
                <v-switch @change="CheckInviteData()" v-model="NewSuiteTemplate.SkipClientInfo" label="Skip Client for Now"/>
                <v-switch v-if="!NewSuiteTemplate.SkipClientInfo" @change="CheckInviteData()" v-model="NewSuiteTemplate.ClientisNonMember" label="Client is not a Member yet"/>
                <UserLookup :UsersLookupArray="UsersArray"  :ModelProp="'Client'" :RecordObj="NewSuiteTemplate" @UpdateUserLookupProp="UpdateUserLookupProp"
                :FieldLabel="'Client'" :Rules="[$store.state.formrules.required]" v-if="!NewSuiteTemplate.ClientisNonMember && !NewSuiteTemplate.SkipClientInfo"
                />
                 <UserInviteForm :System="System" :SystemEntities="SystemEntities" v-if="NewSuiteTemplate.ClientisNonMember && NewSuiteTemplate.InviteData && !NewSuiteTemplate.SkipClientInfo"
                :editedInvite="NewSuiteTemplate.InviteData" @SendInvitetoUser="PrepareInvitetoUser" @CancelInvitingUserEmail="CancelInvitingUserEmail"
                :SystemTableFilters="SystemTableFilters" :InviteText="'Update'" :ShowCancel="false"
                />
                
                 <ContentEditableField style="padding:15px;"
                :FieldObject="NewSuiteTemplate" :FieldName="'Message_to_Client'"
                :FieldValue="NewSuiteTemplate.Client_Message" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
               
            </v-card-text>
            <v-card-actions>
                <v-btn @click="CancelSaveSuiteTemplate()" dark outlined color="warning">
                    Cancel
                </v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn dark outlined color="green" @click="SaveSuiteTemplate()">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- <div :class="$route.name === 'MyMarketplaceTemplates' ? 'v-main__wrap' : 'v-main__wrap'" style="margin-top: 60px;"> -->
     <LandingPageSingle v-if="StoreActiveTemplate && ActiveTemplatechild && PreviewMode" :ActiveTemplate="ActiveTemplatechild" :System="System" :SystemEntities="SystemEntities"
        :SitePages="SitePages" :style="AltBar ? 'margin-top: 106px;' : 'margin-top: 236px;'" @TogglePreviewingMode="TogglePreviewingMode"
        />
        <!-- margin-top: 175px; only if not "STD Bar" -->
    <div v-if="!PreviewMode">
         <!-- <v-card-text v-if="ActiveTemplate && ViewingTemplate && ActiveTemplate.Primary_Category && ActiveTemplate.Primary_Category.Name === 'Page'"> -->
          <!-- <v-btn @click="DeactivateActiveTemplateComponent()">Close</v-btn>
          <v-btn @click="DeactivateActiveTemplateComponent(true)">Install</v-btn> -->
          <!-- <LandingPageSingle :ActiveTemplate="ActiveTemplate" :System="System" :SystemEntities="SystemEntities"
      />
        </v-card-text> -->       
        
        <v-card-title v-if="ActiveTemplate && !ActiveTemplatechild">
            <!--  @click="CancelTemplateView()" -->
          <v-btn dark outlined color="error" @click="$route.name === 'MarketplaceTemplate' ? RoutetoAll() : CancelTemplateView()">
            <v-icon>mdi-keyboard-return
            </v-icon>
            All My Templates
            </v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn  @click="ConfirmDeleteTemplate(ActiveTemplate)" dark outlined color="error">
                <v-icon>mdi-delete-forever
                </v-icon>Delete
            </v-btn>
            <v-btn v-if="RAUserObj && TransfertoRA && !ActiveTemplate.RATemplateid" @click="ConfirmTransfertoRA(ActiveTemplate)" dark outlined class="RAWebBtnGraddark">
                <v-icon>mdi-transfer
                </v-icon>Transfer to RA
            </v-btn>  
            <v-btn v-if="RAUserObj && TransfertoRA && ActiveTemplate.RATemplateid" disabled dark outlined class="RAWebBtnGraddark">
                <v-icon>mdi-transfer
                </v-icon>Already on RA
            </v-btn>          
        </v-card-title>
        <v-card-title>
            <!--  :to="'/MarketplaceTemplate/'+ActiveTemplate.id" -->
            <!-- <v-btn v-if="ActiveTemplate && !PreviewMode" @click="TogglePreviewingMode()" absolute top right dark outlined color="red" >
                {{ActiveTemplate.id}}
            </v-btn> -->
            <v-btn v-if="ActiveTemplate" @click="TogglePreviewingMode()" dark outlined color="blue" >
                Preview
            </v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn v-if="ActiveTemplate" @click="ActivateSuiteTemplateDialog()" dark outlined  class="RAWebBtnGraddark">
                Create Suite Template
            </v-btn>
            <v-menu			
                  :close-on-content-click="false"                            									
                  transition="scale-transition"                            									
                  >									
                  <template v-slot:activator="{ on }">						
                  <v-btn v-if="!ActiveTemplate" v-on="on" outlined>Add Template</v-btn>
                  </template>
                  <v-card tile>
                    <v-card-title class="black white--text">
                        Choose Below
                    </v-card-title>
                    <v-card-text>
                        <v-select return-object v-model="NewTempPrimCat" label="Primary Category" :items="PrimaryCategories" item-text="Name"/>
                        <v-select return-object v-model="NewTempSecCat" label="Secondary Category" :items="SecondaryCategories" item-text="Name"/>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn dark outlined color="warning">Cancel</v-btn>
                    <v-spacer>
                    </v-spacer>
                    <v-btn @click="ActivateMarketTemplateDialog()" dark outlined color="success">Process</v-btn>
                    </v-card-actions>
                  </v-card>  	
                </v-menu>
        </v-card-title>
        <v-card-text v-if="!ActiveTemplate">
            
          <v-row dense>
        <v-col
         v-for="temp in MarketplaceTemplatePackages" :key="temp.itemObjKey"
          :cols="TemplateCols"
        >
          <v-card flat tile width="300" class="mx-auto storecard transparent">
            <v-hover v-slot="{ hover }">
              <v-img class="item__overlayup black" height="200" contain :src="temp.Picture">
                <v-expand-transition>
                    <v-card tile
                        v-if="hover"
                        class="transition-fast-in-fast-out white--text"
                        style="height: 100%;background-color: rgba(40,50,60,0.7)"
                    >
                    <v-card-subtitle style="padding-bottom:0px;font-size: 1.2em;" class="white--text">
                            {{temp.Name}}
                        </v-card-subtitle>
                        <v-card-subtitle style="padding-top:10px;font-weight: bold;" class="white--text">
                            <v-avatar size="30" class="blue" >
                            <img v-if="userLoggedIn.Profile_Photo "
                            :src="userLoggedIn.Profile_Photo "    
                            style="object-fit: cover;"          
                            >
                            <img v-if="!userLoggedIn.Profile_Photo "
                                src="@/assets/BlankProfilePic.png"   
                                style="object-fit: cover;"           
                            >                      
                            </v-avatar>
                            {{temp.Owner.Full_Name}}
                        </v-card-subtitle>
                        <v-card-text class="white--text">
                        <div :id="temp.id+'smartdescription'">
                                <span  v-html="temp.Description ? smart_substr(temp.Description,200) : ''">
                                </span>
                            </div>  
                        </v-card-text>
                    </v-card>
                </v-expand-transition>
              </v-img>
            </v-hover>
            <v-card class="item__overlaydown" rounded elevation="8" :style="'background-image: radial-gradient(circle, rgba(252,70,107,0.1) 0%, rgba(63,94,251,0.1) 100%);'">
                <!-- <v-card class="item__overlaydown" rounded elevation="8" :style="'background-image: url('+require('@/assets/seamless_reed_basket_pattern.jpg')+';background-repeat: repeat;'"> -->
               <v-list dense class="transparent">
                <!-- only if FREE -->
                    <div v-if="temp.Price === 'Free'" style="position: absolute; right: 0px;top: -35px;">
                      <v-img height="60" contain :src="$vuetify.theme.dark ? require('@/assets/Gift_Box_Dark.png') : require('@/assets/Gift_Box_Light.png')"/>
                    </div>
                    <div class="title-ribbon">
                          <v-card-subtitle  class="mediumoverline white--text">
                             {{temp.Price === 'Free' ? temp.Price : CurrencyFormatter(temp.Price,$store.state.DefaultCurrency.Currency)}}
                        </v-card-subtitle>                      
                    </div>
                     <div style="position: absolute; right: 0px;top: 0px;">
                      <v-avatar size="70">
                      <v-img contain src="@/assets/logo.png"/>
                      </v-avatar>
                    </div>
                    </v-list>
                <v-card-actions class="justify-center" style="padding-top:60px;padding-bottom:15px;">
                    <v-btn color="RAWebBtnGraddark" dark v-if="RAUserObj && !temp.RATemplateid" @click="ActivateActiveTemplateComponent(temp,true)">
                        <span v-if="!MiniView">
                        Add to RA
                        </span>
                        <v-icon>
                            mdi-cart
                        </v-icon>
                        </v-btn>
                    <v-spacer>
                    </v-spacer>
                    <v-btn outlined dark color="blue" @click="ActivateActiveTemplateComponent(temp)">
                        <span v-if="!MiniView">
                        View
                        </span>
                    <v-icon v-if="MiniView">mdi-eye</v-icon>
                        </v-btn>  
                </v-card-actions>
            </v-card>
          </v-card>
        </v-col>
          </v-row>
        </v-card-text>
    </div>
    <v-layout class="justify-center background" v-if="!Refreshing && ActiveTemplate && !Editing && !PreviewMode">
    <div style="padding-top: 30px;padding-left:20px;padding-right:20px;" class="align-content-center flex xl11 lg11 md11 sm11 xs11">
    <v-card elevation="0" class="siteconfigcard">
    <!-- <v-card v-if="!Refreshing && ActiveTemplate && !Editing" height="100%" width="100%"> -->
        <v-card-title>
           <v-text-field v-model="ActiveTemplate.Name" label="Name"/>
        </v-card-title>
        <v-card-subtitle>
            
        <ContentEditableField style="padding:15px;"
                :FieldObject="ActiveTemplate" :FieldName="'Description'"
                :FieldValue="ActiveTemplate.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
        </v-card-subtitle>
         <v-card-text>
            <v-text-field label="Price" v-model.number="ActiveTemplate.Price" />
            <v-select label="Publish Status" :items="['Draft','Published']" v-model="ActiveTemplate.PublishStatus" />
        </v-card-text>
       <v-card-text>									
                	 <!-- <input ref="SampleCoverIMGfile" type="file"  @change="NewSaveSampleCoverIMGselected($event)"/>								 -->
               <v-img class="black" height="200" contain v-if="!SampleCoverIMG && ActiveTemplate.Picture"									
                    :src="ActiveTemplate.Picture"									
                    >
                    <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                    <input id="SampleCoverIMGfile" type="file"  style="visibility: hidden;display: none;" @change="NewSaveSampleCoverIMGselected($event)"/>	
                    <label for="SampleCoverIMGfile">
                        <v-chip style="cursor: pointer;" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                        </label>
                    </v-row>
               </v-img>									
                    <v-img height="200" contain v-if="SampleCoverIMG"									
                    :src="SampleCoverIMG"									
                    >
                   <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                    <input id="SampleCoverIMGfile" type="file"  style="visibility: hidden;display: none;" @change="NewSaveSampleCoverIMGselected($event)"/>	
                    <label for="SampleCoverIMGfile">
                        <v-chip style="cursor: pointer;" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                        </label>
                    </v-row>
               </v-img>										
                    <v-img height="200" contain v-if="!ActiveTemplate.Picture && !SampleCoverIMG"									
                    src="@/assets/ImageHolder.png"									
                   >
                    <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                    <input id="SampleCoverIMGfile" type="file"  style="visibility: hidden;display: none;" @change="NewSaveSampleCoverIMGselected($event)"/>	
                    <label for="SampleCoverIMGfile">
                        <v-chip style="cursor: pointer;" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                        </label>
                    </v-row>
               </v-img>									
                <v-btn  outlined color="sucess" v-if="SampleCoverIMG" @click="SaveSampleCoverIMG()">									
                    Save Picture									
                </v-btn>									
            </v-card-text>	
         <v-btn @click="UpdateMarketTemplate()">									
                    Save									
                </v-btn>
                <!-- <v-switch v-model="Editing" label="Edit"/> -->
        <!-- <v-card-text v-if="ActiveTemplate.Components">
            <v-list>
                 <v-card-title>
                    Components
                 </v-card-title>
            <v-list-item @click="RoutetoTemplateComponentItem(item)" v-for="item in ActiveTemplate.Components" :key="item.itemObjKey">
                {{item.TemplateInfo.Component_Name}}
            </v-list-item>
            </v-list>
        </v-card-text> -->
    </v-card>    
    </div>
    <div style="padding-top: 30px;padding-left:20px;padding-right:20px;" class="align-content-center flex xl11 lg11 md11 sm11 xs11" v-if="ActiveTemplate && ActiveTemplate.Primary_Category.Name === 'Site'">
    <v-card elevation="0" class="siteconfigcard">
        <v-list v-if="!ActiveTemplatechild">
            <v-card-subtitle class="justify-end mediumoverline">
                Pages
                    <v-btn right absolute outlined @click="AddNewPage()" >Add Page <v-icon>mdi-plus</v-icon></v-btn>
            </v-card-subtitle>
            <!-- <v-list-item class="justify-end">
             <v-btn right outlined @click="AddNewPage()" >Add Page <v-icon>mdi-plus</v-icon></v-btn>
            </v-list-item> -->
            <v-list-item @click="ActivateTemplateChild(child)" class="detailslistoutline" v-for="child in ComputedTemplateChildren" :key="child.itemObjKey">
                {{child.Name}}
            </v-list-item>
        </v-list>
    </v-card>
    </div>
    </v-layout>
    <v-main  v-if="!Refreshing && Editing && !PreviewMode">
    <v-card class="transparent" flat tile width="100%" height="100%" >
        <v-card-subtitle class="mediumoverline">
            {{ActiveTemplate.Name}}
        </v-card-subtitle>
         <v-card-title>
          <v-btn outlined @click="Refresh()">Back
            </v-btn>
            <v-spacer>
            </v-spacer>
            {{ActiveTemplate.id}}
           <!-- <v-chip @click="AddNewPage()" >Add Page <v-icon>mdi-plus</v-icon></v-chip> -->
        </v-card-title>
       <!-- <v-card-text v-if="ActiveTemplate && ActiveTemplate.Primary_Category.Name === 'Site' && ActiveTemplatechild"> -->
        
        <!-- <v-btn @click="ViewingTemplate = !ViewingTemplate">ViewingTemplate</v-btn> -->
         
    </v-card>
    </v-main>
    <NewLandingPage :ActiveTemplate="ActiveTemplatechild" :System="System" :SystemEntities="SystemEntities" style="margin-top: 0px;"
                :SitePages="SitePages"v-if="StoreActiveTemplate && ActiveTemplate && ActiveTemplate.Primary_Category.Name === 'Site' && ActiveTemplatechild && !Refreshing && Editing && !PreviewMode"
                />
       <!-- </v-card-text> -->
                <NewLandingPage :ActiveTemplate="ActiveTemplate" :System="System" :SystemEntities="SystemEntities" style="margin-top: 0px;"
                :SitePages="SitePages" v-if="StoreActiveTemplate && ActiveTemplate && ActiveTemplate.Primary_Category.Name === 'Page' && !Refreshing && Editing && !PreviewMode"
                />
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import NewLandingPage from '@/components/WebPages/NewLandingPage';
import LandingPageSingle from '@/components/WebPages/LandingPageSingle';	
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'	
import UserLookup from '@/components/General/UserLookup'
import UserInviteForm from '@/components/General/UserInviteForm';	
export default {
    props: ['System','SystemEntities','SitePages','RADB','SystemTableFilters'],
    components: {NewLandingPage,LandingPageSingle,ContentEditableField,UserLookup,UserInviteForm},
    data() {
        return {
            rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
            NewSuiteTemplate: {},
            DefaultNewSuiteTemplate: {},
            SuiteTemplateDialog: false,
            ViewingTemplate: false,
            NewTempPrimCat: '',
            NewTempSecCat: '',
            PrimaryCategories: [
                {ID:1000001,Name: 'Site'},
                {ID:1000002,Name: 'Page'}
            ],
            SecondaryCategories: [
                {ID: 1000001, Name: 'Website'},
                {ID: 1000002, Name: 'Social Network'},
                {ID: 1000003, Name: 'Group Site'},
                {ID: 1000004, Name: 'Featured Member'}
            ],
            TransfertoRA: false,
            Editing: false,
            NewCoverIMG: '',
            SampleCoverIMG: '',
            Refreshing: false,
            ActiveTemplate: '',
            ActiveTemplatechild: '',
            PreviewingMode: false,
            SuiteTemplate: {templates: []}
        }
    },	
    computed:{
        MarketTemplatesDBRef(){
            return db.collection('suitetemplates').doc(this.SuiteTemplate.id).colleciton('templates')
        },
        UsersArray(){
            return this.$store.state.Users
        },
        AltBar(){
            return this.StoreActiveTemplate && this.StoreActiveTemplate.TemplateProps.PublicNavbarStyle === 'STD Bar'
        },
        StoreActiveTemplate(){
        return this.$store.state.StoreActiveTemplate
      },
        SiteTemplate(){
            return this.StoreActiveTemplate && this.StoreActiveTemplate.Primary_Category.Name === 'Site'
        },
        WebsiteTemplate(){
            return this.SiteTemplate &&
            this.StoreActiveTemplate.Secondary_Category && this.StoreActiveTemplate.Secondary_Category.Name === 'Website'
        },
        SocialNetworkTemplate(){
            return this.SiteTemplate &&
            this.StoreActiveTemplate.Secondary_Category && this.StoreActiveTemplate.Secondary_Category.Name === 'Social Network'
        },
        FeaturedMemberTemplate(){
            return this.SiteTemplate &&
            this.StoreActiveTemplate.Secondary_Category && this.StoreActiveTemplate.Secondary_Category.Name === 'Featured Member'
        },
        GroupSiteTemplate(){
            return this.SiteTemplate &&
            this.StoreActiveTemplate.Secondary_Category && this.StoreActiveTemplate.Secondary_Category.Name === 'Group Site'
        },
        PreviewMode(){
            return !this.UserCanEdit || this.UserCanEdit && this.PreviewingMode
        },
        UserCanEdit(){
            return this.ActiveTemplate && this.ActiveTemplate.Ownerid === this.userLoggedIn.id || 
            this.$route.name === 'MyMarketplaceTemplates'
            //for support must add  || this.userIsAdmin once past testing
        },
        BuilderView(){
      return this.$store.state.BuilderView
      },
      NetworkBuilderView(){
            return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        UserMarketAccount(){
      return this.UserBillingAccounts ? this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Market Account') : ''
    },
       UserBillingAccounts(){
      return this.userLoggedIn && this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : []
    },
        AppMonetizeBU(){
            return this.System.Monetization_BU ? this.System.Monetization_BU : ''
        },
        RAAdmin(){
        return this.RAUserObj && this.RAUserObj.rolesarrayDBRules && this.RAUserObj.rolesarrayDBRules.SystemAdmin
    },
    RAUserObj(){
        return this.$store.state.RAUserObj
    },
        TemplateCols(){
            let cols = 12
            if(this.$vuetify.breakpoint.xl){
                cols = 2
            }
            else if(this.$vuetify.breakpoint.lg){
                cols = 3
            }
            else if(this.$vuetify.breakpoint.md){
                cols = 4
            }
            else if(this.$vuetify.breakpoint.sm){
                cols = 6
            }
            return cols
        },
        MyTemplatesView(){
            return this.$route.name === 'MyMarketplaceTemplates'
        },
        ComputedTemplateChildren(){
            return this.ActiveTemplate && this.ActiveTemplate.Children ? 
            this.MarketplaceTemplates.filter(temp => {
                return this.ActiveTemplate.Children.includes(temp.id)
            }) :
            []
        },
        MarketplaceTemplatePackages(){
            return this.MyMarketplaceTemplates.filter(temp => {
                return !temp.Parentid
            }).map(temp => {
                let temppbj = Object.assign({},temp)
                if(typeof temppbj.Price === 'undefined'){
                    temppbj.Price = 'Free'
                }
                return temppbj
            })
        },
        SuiteTemplateView(){
            return this.$route.name === 'SuiteTemplateWebsite'
        },
        MarketplaceTemplates(){
        return this.SuiteTemplateView ? this.SuiteTemplate.templates : this.MyMarketplaceTemplates.concat(this.OtherMarketplaceTemplates)
        },
        OtherMarketplaceTemplates(){
        return this.$store.state.OtherMarketplaceTemplates
        },
        MyMarketplaceTemplates(){
        return this.$store.state.MyMarketplaceTemplates
        },
        SuiteTemplates(){
        return this.MySuiteTemplates.concat(this.OtherSuiteTemplates)
        },
        OtherSuiteTemplates(){
        return this.$store.state.OtherSuiteTemplates
        },
        MySuiteTemplates(){
        return this.$store.state.MySuiteTemplates
        },
        UsersStore(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        ActiveFeaturedMember(){
            return this.$store.state.ActiveFeaturedMember
        },
        NewSitePageid(){
        return this.$store.state.NewSitePageid
      },
    },
    watch: {
        ActiveTemplate: {
            handler: function(newvalue, oldvalue) {
             //console.log(oldvalue, newvalue,'this.$store.state',this.$store.state)
                if(newvalue !== oldvalue){   
                    if(!newvalue.id && this.BuilderView){
                        this.$store.commit('setBuilderView','')
                    }   
                    if(newvalue.id){
                        this.$store.commit('setStoreActiveTemplate',newvalue)
                        if(newvalue.Primary_Category && newvalue.Primary_Category.Name === 'Site'){
                            this.$store.commit('setActiveTemplateSite',newvalue)
                            this.SetBuilderView()
                        }
                    }         
                    
                    if(newvalue && newvalue.Secondary_Category && newvalue.Secondary_Category.Name === 'Featured Member'){
                        //okay it's a fet member do we have one active?
                        if(!this.ActiveFeaturedMember){
                            //we need to set active member how do we do that?
                            let query = db.collection('featuredmembers').doc(this.userLoggedIn.id)
                            query.onSnapshot(snapshot => {
                                let FeaturedMember = snapshot.data()
                                FeaturedMember.id = this.userLoggedIn.id
                                FeaturedMember.Query = query
                                console.log('this.FeaturedMember ',FeaturedMember)
                                this.$store.dispatch('GetActiveFeaturedMember',FeaturedMember) 
                                console.log(this.$store.state.ActiveFeaturedMember)
                            })
                        }
                    }
                }
            },deep: true
        },
        // ActiveTemplatechild: {
        //     handler: function(newvalue, oldvalue) {
        //         if(newvalue !== oldvalue){
        //             if(newvalue.id){
        //                 this.$store.commit('setStoreActiveTemplate',newvalue)
        //                 console.log(this.$store.state.StoreActiveTemplate)
        //             }   
        //         }
        //     },deep: true
        //decided not to because now the StoreActiveTemplate is parent and could receive nabar items etc!
        // },
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['PerformSitePageAddition','RoutetoTemplatePage']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        if(!this.MyTemplatesView && this.SuiteTemplateView){
            this.GetSuiteTemplate()
        }
        else if(!this.MyTemplatesView){
            this.GetMarketplacetemplate()
        }
    },
    methods:{
        PrepareInvitetoUser(editedInvite){
            this.NewSuiteTemplate.InviteData = editedInvite
            console.log('this.NewSuiteTemplate',this.NewSuiteTemplate)
        },
        SendInvitetoUser(editedInvite){
            //lol, technicall first complete the frigging data
            this.$store.commit('SetSocialItemInteractMethodProp',editedInvite) 
            this.$store.commit('SetSocialItemInteractMethod','SendInvitetoUser')
        },
        CancelInvitingUserEmail(){
            //gonna leave it here but blank. nothing went to appnot, why cancell right?
            //this.$store.commit('SetSocialItemInteractMethod','CancelInvitingUserEmail') 
        },
        CheckInviteData(){
            if(!this.NewSuiteTemplate.InviteData){
                this.NewSuiteTemplate.InviteData = {}
            }
            if(this.NewSuiteTemplate.SkipClientInfo){
                delete this.NewSuiteTemplate.InviteData
            }
        },
        UpdateUserLookupProp(Prop,Value,RecordObj){
            RecordObj[Prop] = Value
        },
        CancelSaveSuiteTemplate(){
            this.SuiteTemplateDialog = false
            this.NewSuiteTemplate = Object.assign({},this.DefaultNewSuiteTemplate)
        },
        FAKESaveSuiteTemplate(){
            console.log(this.StoreActiveTemplate)
        },
        SaveSuiteTemplate(){
            //this is bloody complex you heard? What's gonna happen now?
            //For all practical purposes this is genuineline going to a deep level replication. 
            //However there is a dialog happening on this, and the dialog is quite simply put the details of the parties invovled
            //the purpose of the system, who request it blablabla right?
            //so we set Invitees and stuff, and these could either be people on the App, or people you invite. This means...The WD Member Group needs access to invite another MemGroup type, the bus type
            //remember the cycle right? So yes that's about the long and shot of it 
            //BUT BERWARE NOW TECHNICALLY WE NEED A "PUSH TO SUITE TEMPLATE" AS WELL so suitetemplates records is one level higher than marketplace templates you get?
            //So let's just imagine how this shit is gonna work actually. 
            //Technically we will be working on a couple of things, but routes is what matters most. This will be "Your-Domain" which then receive some interesting shit. 
            //1. Website 2. Social Network 3. Database App 4. Documentation
            //So I guess...What type of template is this, then push accordingly. So
            //main record the main SuiteTemplate record is this very short record of client and owner and shit, very basic. 
            //then it has website and website templates and shit....uhm...crap! How will this look?
           this.$emit('ActivateProcessing',true)	
            let path = db.collection('suitetemplates')
            let ref = path.doc()
            let newdocid = ref.id
            let newdocpath = path.doc(newdocid)
            let newtempdoc = Object.assign({},this.StoreActiveTemplate)
            let assets = this.StoreActiveTemplate.Assets
            let totalassets = assets.length
            let assetcounter = 0
            let newroute = '/Your-Domain/'+newdocid+'/'
            let newdocmodpath = ''
            let childcounter = 0
            delete newtempdoc.Assets
            let children = this.ComputedTemplateChildren.concat([newtempdoc])
            let totalchildren = children.length
            let user = {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id}
            let date = new Date()
            let systemid = process.env.VUE_APP_RA_SYSTEM_ID
            let systemurl = this.System.ClientAppURL
            let defobj = {
                ...this.NewSuiteTemplate,
                Templateid: this.StoreActiveTemplate.id,
                Systemid: systemid,
                ClientAppURL: systemurl,
                PublishStatus: 'Draft',
                Owner: user,          
                Ownerid: user.id,
                RouteName: this.$route.name,
                Created_By: user,          
                Created_Byid: user.id,
                Modified_By: user,          
                Modified_Byid: user.id,
                Created_On: date,
                Modified_On: date,
                PluginDataBase: newtempdoc.PluginDataBase
            } 
            if(this.StoreActiveTemplate.Primary_Category.Name === 'Page'){
                //sure some things would change. this would also anyway only happen if we push to suiteTemplates....
            }
            if(this.StoreActiveTemplate.Secondary_Category.Name === 'Website'){
                defobj.PluginDataBase.Website_Builder = {
                    Active: true,
                    Module_Category: {
                        ID: 1000008,Name: 'Module'
                    },
                    Name: 'Website Builder',
                    id: 'Website_Builder'
                }
                newroute = newroute+'Website'
                if(!defobj.InviteData){   
                    this.ProcessNewSuiteTemplate(defobj,newdocpath,newroute,assets,assetcounter,totalassets,children,childcounter,totalchildren) 
                }
                else{
                    //nope first send invite somehow return???
                }
            }
           

        },
        ProcessNewSuiteTemplate(defobj,newdocpath,newroute,assets,assetcounter,totalassets,children,childcounter,totalchildren) {
            newdocpath.set(defobj).then(newdoc => {
                        // newdocmodpath.set(newtempdoc).then(newtempdoc => {
                            //get we done, but we smcked way too much in there...we did assetss as well so...yeah
                            if(assetcounter === totalassets){
                                if(childcounter === totalchildren){
                                    //Actually, navigate to it!!
                                    // this.CancelSaveSuiteTemplate()
                                    this.$emit('ActivateProcessing',false)
                                    this.$router.push(newroute)
                                }
                                else{
                                    children.map(child => {
                                        newdocpath.collection('templates').doc(child.id).set(child).then(newchild => {
                                            childcounter++
                                            if(childcounter === totalchildren){
                                               //Actually, navigate to it!!
                                    // this.CancelSaveSuiteTemplate()
                                    this.$emit('ActivateProcessing',false)
                                     this.$router.push(newroute)
                                            }
                                        })
                                    })
                                }
                            }
                            else{
                                assets.map(ast => {
                                    newdocpath.collection('Assets').doc(ast.id).set(ast).then(newastdoc => {
                                        assetcounter++
                                        if(assetcounter === totalassets){
                                            if(childcounter === totalchildren){
                                                //Actually, navigate to it!!
                                    // this.CancelSaveSuiteTemplate()
                                    this.$emit('ActivateProcessing',false)
                                     this.$router.push(newroute)
                                            }
                                            else{
                                                children.map(child => {
                                                    newdocpath.collection('templates').doc(child.id).set(child).then(newchild => {
                                                        childcounter++
                                                        if(childcounter === totalchildren){
                                                         //Actually, navigate to it!!
                                    // this.CancelSaveSuiteTemplate()
                                    this.$emit('ActivateProcessing',false)	
                                     this.$router.push(newroute)
                                                        }
                                                    })
                                                })
                                            }
                                        }
                                    })
                                })
                            }
                        // })
                    })
        },
        ActivateSuiteTemplateDialog(){
            //TEchnically we also need a "push to Suite"
            this.SuiteTemplateDialog = true

        },
        RoutetoAll(){
            this.$router.push('/MyMarketplaceTemplates')
            this.CancelTemplateView()
            this.$store.commit('setActiveTemplateSite','')
        },
        RoutetoTemplatePage(page){
            //console.log(page)
            let child = this.ComputedTemplateChildren.find(obj => obj.id === page.id)
            if(!child){
                child = this.ComputedTemplateChildren.find(obj => obj.Name === page.title)
            }
            this.ActiveTemplatechild = ''
            setTimeout(() => {
            this.ActiveTemplatechild = child
            if(!this.Editing){
                //this.ActivateTemplateChild
                this.Editing = true
            }
            }, 20);
            //this.ActiveTemplatechild = child
            //console.log(this.ActiveTemplatechild,this.UserCanEdit,this.PreviewingMode,this.PreviewMode,page,this.ComputedTemplateChildren)
        },
        TogglePublicNavbarEditing(){
            this.$emit('TogglePublicNavbarEditing')
        },
        TogglePreviewingMode(){
            this.PreviewingMode = !this.PreviewingMode
            if(this.PreviewingMode){
                this.$store.commit('setBuilderView','')
                this.TogglePublicNavbarEditing()
                //okay here is another thing. not 1. Check if type, like if website template check StoreActiveTemplate.TemplateProps.Public_Landing_Page. Get matching form computed children
                //2. If found use that, otherwise child[0] will do
                this.CheckDefaultRoute()
                //still need to set others, think group has def root, feat mem not, because it's "route.id" AND "slug"...
            }
            else{
                this.SetBuilderView()
                this.TogglePublicNavbarEditing()
                //this.DeactivateTemplateChild()
            }
        },
        CheckDefaultRoute(){
            let defpageprop = ''
                let defpage = ''
                let defpageroute = ''
                let pagechild = ''
                if(this.WebsiteTemplate){
                    defpageprop = 'Public_Landing_Page'
                }
                else if(this.SocialNetworkTemplate){
                    defpageprop = 'Social_Landing_Page'
                }
                //console.log(defpageprop,this.StoreActiveTemplate,this.StoreActiveTemplate.TemplateProps[defpageprop])
                if(defpageprop && this.ActiveTemplate.TemplateProps[defpageprop]){
                    defpageroute = this.ActiveTemplate.TemplateProps[defpageprop].split('/').join('').split('-').join(' ').split('_').join(' ')
                    pagechild = this.ComputedTemplateChildren.find(obj => obj.TemplateObj.Name === defpageroute)
                    if(pagechild){
                        defpage = pagechild.TemplateObj
                    }
                }
                if(defpage){
                    this.ActivateTemplateChild(pagechild)
                    this.ActiveTemplatechild = pagechild
                }
                else if(this.ComputedTemplateChildren[0]){
                    this.ActivateTemplateChild(this.ComputedTemplateChildren[0])
                }
        },
        PerformSitePageAddition(newpage){
            console.log(newpage,this.NewSitePageid)
            let children = []
            if(this.ActiveTemplate.Children){
                children = this.ActiveTemplate.Children
            }
            children.push(this.NewSitePageid)
            this.MarketTemplatesDBRef.doc(this.ActiveTemplate.id).update({
                Children: children
            }).then(updatedparetn => {
                this.$store.commit('setNewPageSite','')
                this.$store.commit('setNewSitePageid','')
                let acttemp = this.ActiveTemplate
                this.ActiveTemplate = ''
                setTimeout(() => {
                    this.ActiveTemplate = acttemp
                }, 50);
            })
        },
        AssignSocialItemInteractMethod(item,SecondProp){
        //console.log('AssignSocialItemInteractMethod',item,SecondProp)
        this.$store.commit('SetSocialItemInteractMethodProp','') 
        this.$store.commit('SetSocialItemInteractMethod','')
        if(SecondProp){
            this.$store.commit('SetSocialItemInteractMethodProp',SecondProp) 
            this.$store.commit('SetSocialItemInteractMethod',item)
        }
        else if(item){
            this.$store.commit('SetSocialItemInteractMethod',item)
        }
        },
        AddNewPage(navbar,SubPage,parentindex){
            //console.log(navbar,SubPage,parentindex)
          let type = 'Public'
          if(this.ActiveTemplate.Secondary_Category.Name === 'Social Network'){
            type = 'Members Only'
          }
          this.$store.commit('setNewPageSite',this.ActiveTemplate)
          this.AssignSocialItemInteractMethod('ToggleNewPageDialog',type)
        },
        ActivateTemplateDialog(primcat,seccat){
            console.log(primcat,seccat)
            //the main issue being the route params assigned here, that's it
            //that equates to
            //1.RouteName WTf it means don't even care don't think it matters
            //2. the "array" values below meaning jack shit, if social network, but yes applicable if website or feat mem
            //3.Templateid like ???
            //4. Components for site will be empty
            //was passing primcat,index
            //ComputedSitePages
            //console.log('index',index,this.ComputedNavbarPages[index])
            let ref = this.MarketTemplatesDBRef.doc()
            let Templateid = ref.id
            let user = {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id}
            let date = new Date()
            let systemid = process.env.VUE_APP_RA_SYSTEM_ID
            let systemurl = this.System.ClientAppURL
            let defobj = {
                Templateid: '',
                Systemid: systemid,
                ClientAppURL: systemurl,
                PublishStatus: 'Draft',
                Owner: user,          
                Ownerid: user.id,
                RouteName: this.$route.name,
                Created_By: user,          
                Created_Byid: user.id,
                Modified_By: user,          
                Modified_Byid: user.id,
                Created_On: date,
                Modified_On: date,
                // TemplateProps: {},
                TemplateObj: {},
                Primary_Category: primcat,
                Secondary_Category: seccat
            } 
            let payload = Object.assign({},defobj)
            payload.Primary_Category = primcat
            if(primcat.Name === 'Site'){
                payload.Templateid = Templateid
                //yes because slug is the page
                payload.TemplateProps = {
                    //Children: this.ComputedNavbarPages,
                    //ComputedSitePages but yeah gonna need simply template ids right?
                     //[this.SelectedNavbar.Prop]: this.NavListArray
                     //should cover for all I guess?
                     Navlist: [],
                     NavlistProp: ''
                }
                let array = ['PublicNavbarDark','PublicNavbarStyle','PublicNavbarText','PublicNavbarSelectedText','PrependedPublicNavbarActions','AppendedPublicNavbarActions','Public_NavBar_IMG','PublicNavbarItemSelectBG','PublicNavbarItemHoverBG']
                if(seccat.Name === 'Featured Member'){
                    payload.TemplateProps.NavlistProp = 'MenuItems'
                }
                else if(seccat.Name === 'Website'){
                    payload.TemplateProps.NavlistProp = 'PublicNavbarItems'
                }
                else if(seccat.Name === 'Social Network'){
                    payload.TemplateProps.NavlistProp = 'SocialNavbarItems'
                    array = []
                    //??????
                }
                //IRRELEVANT THERE IS NO SITE!!!

                // array.map(prp => {
                //     if(typeof this.Site[prp] !== 'undefined'){
                //         payload.TemplateProps[prp] = this.Site[prp]
                //     }
                // })
                // payload.Components = this.ComputedNavbarPages.map(page => {
                //     let childtempobj = Object.assign({},defobj)
                //     let childpage = this.ComputedSitePages.find(obj => obj.Name === page.Name)
                //     if(childpage){
                //         childtempobj.Templateid = childpage.id
                //     }
                //     else{
                //         childtempobj.Templateid = page.Name.split(' ').join('_')
                //     }
                //     childtempobj.TemplateObj = page
                //     childtempobj.Icon = page.PageIcon
                //     childtempobj.Name = page.Name
                //     return childtempobj
                // })
                //?
            }
            else{
                Templateid = Templateid
                //yes because id is the member id
                let temppage = this.ComputedNavbarPages[index]
                TemplateObj = {
                    Icon: temppage.PageIcon,
                    //how do we get entire page on here?
                    ...temppage
                }
            }
            this.$store.commit('SetSocialItemInteractMethodProp',payload) 
            this.$store.commit('SetSocialItemInteractMethod','ActivateMarketplaceTemplateDialog')
        },
        ActivateMarketTemplateDialog(){
            this.ActivateTemplateDialog(this.NewTempPrimCat,this.NewTempSecCat)
            this.NewTempPrimCat = ''
            this.NewTempSecCat = ''
        },
        ChangeSampleCoverIMGselected(){
            this.$refs('SampleCoverIMGfile')
        },
        smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },	
        // This should be in AppNotifications
        //comes from C:\RANEWApps\DNetGmailAccount\businessandpeople\src\components\SuitePlugins\Blogs\Single.vue
        ActivateTokenPurchaseDialog(){     
        let vm = this
         if(!this.UserMarketAccount && this.AppMonetizeBU){
           vm.$store.commit('setCustomProcessingDialogText','Creating Market Account')
           vm.$store.commit('setCustomProcessingDialog',true)
           const functions = firebase.functions();
           const CreateMarketAccount = functions.httpsCallable('CreateMarketAccount');
           let payload = {
             Data: vm.userLoggedIn,
             AppMonetizeBU: vm.AppMonetizeBU
           }
           CreateMarketAccount(payload).then(result => {
             console.log(result)
             vm.$store.commit('setCustomProcessingDialogText','Done Creating')
             vm.ProcesstokenPurchase()
           })
         }
         else{
           vm.ProcesstokenPurchase()
         }
        console.log('definitley going to pass app notification push token packages etc. refer EmitMarketDialog()')
        let transaction = {
          Business_Unit: this.AppMonetizeBU,
          Business_Unitid: this.AppMonetizeBU.id,
          ROE: Number(this.System.Credit_Tokens_ROE),
          Date: new Date(),
          Type: {ID: 1000001, Name: 'User Token Purchase'},
          
        }
        },
         // This should be in AppNotifications
        CancelTemplateView(){
            this.ActiveTemplate = ''
            this.ActiveTemplatechild = ''
            this.Editing = false
            this.TransfertoRA = false
        },
        ConfirmTransfertoRA(ActiveTemplate){
            confirm('Do you want to place this template into the RapidApps platform marketplace?') && this.ProcessTransfertoRA(ActiveTemplate)
        },
        ProcessTransfertoRA(ActiveTemplate){
             let path = this.RADB.collection('marketplacetemplates')
                let parentref = path.doc()
            let ratemplate = Object.assign({},ActiveTemplate)
            ratemplate.id = parentref.id
             let clientuser = {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id}
            let user = {Full_Name: this.RAUserObj.Full_Name,id: this.RAUserObj.id}
            let date = new Date()
             let children = this.MarketplaceTemplates.filter(temp => {
                    return ActiveTemplate.Children.includes(temp.id)
                }).map(child => {
                    let childref = path.doc()
                    let childobj = Object.assign({},child)
                    childobj.ClientSuiteTemplateid = childobj.id
                    childobj.ClientOwner = clientuser
                    childobj.ClientOwnerid = clientuser.id
                    childobj.Owner = user          
                    childobj.Ownerid = user.id
                    childobj.Created_By = user     
                    childobj.Created_Byid = user.id
                    childobj.Modified_By = user         
                    childobj.Modified_Byid = user.id
                    childobj.Created_On = date
                    childobj.Modified_On = date
                    childobj.id = childref.id
                    childobj.Original_Parentid = childobj.Parentid
                    childobj.Parentid = ratemplate.id
                    return childobj
                })
            let total = children.length
            let running = 0
            ratemplate.ClientSuiteTemplateid = ratemplate.id
            ratemplate.ClientOwner = clientuser
            ratemplate.ClientOwnerid = clientuser.id
            ratemplate.Owner = user          
            ratemplate.Ownerid = user.id
            ratemplate.Created_By = user     
            ratemplate.Created_Byid = user.id
            ratemplate.Modified_By = user         
            ratemplate.Modified_Byid = user.id
            ratemplate.Created_On = date
            ratemplate.Modified_On = date
            ratemplate.Original_Children = ratemplate.Children
            ratemplate.Children = children.map(child => {
                return child.id
            })
            if(children.length > 0){
                children.map(child => {
                path.doc(child.id).set(child).then(childnew => {
                        running++
                        if(running === total){
                            path.doc(ratemplate.id).set(ratemplate).then(parentnew => {
                                this.MarketTemplatesDBRef.doc(this.ActiveTemplate.id).update({
                                    RATemplateid: ratemplate.id
                                }).then(updateddoc => {
                                  this.CancelTemplateView()  
                                })                                
                            })
                        }
                    })
                })
            }
            else{
                path.doc(ratemplate.id).set(ratemplate).then(parentnew => {
                    this.MarketTemplatesDBRef.doc(this.ActiveTemplate.id).update({
                        RATemplateid: ratemplate.id
                    }).then(updateddoc => {
                        this.CancelTemplateView()  
                    })
                })
            }

        },
        ConfirmDeleteTemplate(ActiveTemplate){
            confirm('This will delete the template forever and there is no undo, are you sure?') && this.ProcessDeleteTemplate(ActiveTemplate)
        },
        ProcessDeleteTemplate(ActiveTemplate){
            if(!ActiveTemplate.Children){
            this.MarketTemplatesDBRef.doc(ActiveTemplate.id).delete().then(deldoc => {
                this.CancelTemplateView()
            })
            
            }
            else{
                let children = this.MarketplaceTemplates.filter(temp => {
                    return ActiveTemplate.Children.includes(temp.id)
                })
                let total = children.length
                let running = 0
                children.map(child => {
                    this.MarketTemplatesDBRef.doc(child.id).delete().then(childdel => {
                        running++
                        if(running === total){
                            this.MarketTemplatesDBRef.doc(ActiveTemplate.id).delete().then(deldoc => {
                                this.CancelTemplateView()
                            })
                        }
                    })                    
                })
            }
        },
        SetBuilderView(){
            if(this.UserCanEdit){
                //for support must add  || this.userIsAdmin once past testing
                this.$store.commit('setBuilderView',{Name: 'Marketplace Site'})
            }                
        },
        ActivateActiveTemplateComponent(temp,toRA){
        this.ActiveTemplate = temp
        this.TransfertoRA = toRA
        if(this.$route.name === 'MyMarketplaceTemplates'){
            if(this.ActiveTemplate && this.ActiveTemplate.Primary_Category && this.ActiveTemplate.Primary_Category.Name === 'Site'){
                this.SetBuilderView()
                
            }
            else if(this.BuilderView){
                this.$store.commit('setBuilderView','')
            }
        }
      },
        SaveSampleCoverIMG(){									
            if(this.NewCoverIMG){		
                this.$emit('ActivateProcessing',true)								
                let vm = this                									
                var storageRef = 'UserAssets/'+vm.ActiveTemplate.Ownerid+'/Assets/'+new Date()+'/'+vm.NewCoverIMG.name									
                vm.PrepareSampleCoverThumbnail(vm.NewCoverIMG.name,vm.NewCoverIMG,storageRef)									
                									
            }									
        },
        PrepareSampleCoverThumbnail(filename,file,storageref){									
        let vm = this									
        return new Promise(function(resolve, reject) {									
        file.tmpsrc = URL.createObjectURL(file)									
              //this.OutboundPhotosforUpload.push(file)									
              var img = document.createElement("IMG");									
                img.setAttribute('width',150)									
                img.setAttribute('src',file.tmpsrc)									
                document.body.appendChild(img)									
                									
                img.onload = function() {									
                var c = document.createElement("canvas");									
                var ctx = c.getContext("2d");									
                var canvasRatio = img.naturalHeight / img.naturalWidth									
                c.width = 400									
                c.height = c.width*canvasRatio									
                ctx.drawImage(img, 0, 0, c.width, c.height);									
                c.toBlob(blob => {									
                  let blobfile = new File([blob], filename, {									
              type: file.type,									
            });									
            //console.log(blobfile)									
            var storageRef = firebase.storage().ref(storageref+'_thumb')									
                document.body.removeChild(img)									
                var uploadTask = storageRef.put(blobfile)									
                uploadTask									
                  .then(snapshot => snapshot.ref.getDownloadURL())									
                    .then((thumburl) => {									
                      //console.log(thumburl)	
                      vm.$emit('ActivateProcessing',false)									
                      vm.ActiveTemplate.Picture = thumburl	
                      vm.SampleCoverIMG = ''
                      vm.NewCoverIMG = ''								
                      //vm.CancelEditTestimony()									
                    })									
              })									
                }									
        })									
      },	
        NewSaveSampleCoverIMGselected(event){								
            this.NewCoverIMG = event.target.files[0]
            console.log('this.NewCoverIMG',this.NewCoverIMG)									
            this.SampleCoverIMG = URL.createObjectURL(this.NewCoverIMG)									
        },		
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
            FieldObject[prop] = value
            //console.log(AdditionalSaveMethod)
            if(AdditionalSaveMethod){
                this[AdditionalSaveMethod]()
            }
        },
        UpdateMarketTemplate(){
            let updateobj = {
                PublishStatus: this.ActiveTemplate.PublishStatus,
                Name: this.ActiveTemplate.Name,
                Description: this.ActiveTemplate.Description,
            }
            if(this.ActiveTemplate.Picture){
                updateobj.Picture = this.ActiveTemplate.Picture
            }
            if(this.ActiveTemplate.Price){
                updateobj.Price = this.ActiveTemplate.Price
            }
            this.MarketTemplatesDBRef.doc(this.ActiveTemplate.id).update(updateobj).then(updateddoc => {
                if(this.ComputedTemplateChildren.length > 0){
                    this.ComputedTemplateChildren.map(childtemp => {
                        this.MarketTemplatesDBRef.doc(childtemp.id).update({
                            PublishStatus: this.ActiveTemplate.PublishStatus,
                        })
                    })
                }
            })
            //and really some snackbar inbetween and shit
        },
        ActivateTemplateChild(child){
            this.Editing = true
            this.ActiveTemplatechild = child
            //okay so if Guest, then activate the Builderappbarview...
        },
        DeactivateTemplateChild(){
            this.ActiveTemplatechild = ''
        },
        Refresh(){
            this.Refreshing = true
            if(this.ActiveTemplatechild){
                this.ActiveTemplatechild = ''
                this.Editing = false
            }
            else{
                this.Editing = false
            }
            setTimeout(() => {
                this.Refreshing = false
            }, 300);
        },
        // RoutetoTemplateComponentItem(item){
        //     //this is going to be hella difficult!!!!
        //     //essentially now I need to edit the item "as is"
        //     //with the first case being featuredmembereditot, this is what I need to do...
        //     //challenge being...there is no route I can use
        //     //if I used FeaturedMember Editor it won't work because the item won't exist, cannot be called on, cannot push an entire page
        //     //additionally, this route is now unique, what route would I even use right?
        //     //Do I need to get a "page preview" route now? And now what happens with things like data table, would I get sample routes for that as well?
        //     //did we even think this through?
        //     //No of course we clearly did not think this through. 
        //     //nothing is going to render unless we undertake a MAMMOTH exercise to get everything "preview ready"
        //     //Relax...what if we used landingpagesingle? or Newlandingpage?
        //     this.ActiveTemplateComponent = item
        // },
        GetSuiteTemplate(){
            this.SuiteTemplate = this.SuiteTemplates.find(obj => obj.id === this.$route.params.id)
            //console.log('this.SuiteTemplate',this.SuiteTemplate)
            if(this.SuiteTemplate){
                this.GetMarketplacetemplate()
            }
        },
        GetMarketplacetemplate(){
            let template = this.MarketplaceTemplates.find(obj => !obj.Parentid)
            //console.log('template',template,this.MarketplaceTemplates,this.$store.state)
            if(!template){
                 if(!this.userLoggedIn){
                this.$router.push('/404')  
                }
                else{
                this.$router.push('/404')
                } 
            }
            else{
                //undid if(template.Ownerid !== this.userLoggedIn.id && !this.userIsAdmin){ as admin also get to view preview mode first right
                if(template.Ownerid !== this.userLoggedIn.id){
                    //this.$router.push('/PermissionError')
                    this.ActiveTemplate = template
                    //I know this is not right but give me a second
                    this.CheckDefaultRoute()  
                }
                else{
                 this.ActiveTemplate = template   
                 if(this.ActiveTemplate && this.ActiveTemplate.Primary_Category && this.ActiveTemplate.Primary_Category.Name === 'Site'){
                    this.SetBuilderView()
                    this.$store.commit('setActiveTemplateSite',template)    
                    this.CheckDefaultRoute()                
                }
                }
                
            }
        }
    }
}
</script>

<style>
</style>



